<template>
    <ListaEtapa />
</template>

<script>
import ListaEtapa from '@/components/Etapa/ListaEtapa'
export default {
  components: {ListaEtapa},
  data(){
    return{}
  }
}
</script>

<style>

</style>